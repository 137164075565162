import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'

export default function getInpo() {
  const inpoData = ref(null)

  const getInpoData = () => {
    axiosInstances.entities.get(`internalops/entity/${router.currentRoute.params.id}`).then(res => {
      inpoData.value = res.data.data
      inpoData.value.paymentdetails = res.data.data.paymentdetails ?? {} // this because payment details aren't found in some inpos
      inpoData.value.address = res.data.data.address.length !== 0 ? res.data.data.address : [{}] // this because address aren't found in some inpos
    })
  }

  getInpoData()

  return { inpoData }
}
