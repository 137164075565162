<template>
  <div class="mb-1">
    <validation-provider
      #default="validationContext"
      name="Image"
      :rules="rules"
    >
      <b-form-group
        :label="label"
        :label-for="imageId"
      >
        <b-file
          :id="imageId"
          accept="image/*"
          :multiple="isMultiple"
          :state="getValidationState(validationContext)"
          @input="onInput"
        />
        <b-form-valid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-valid-feedback>
      </b-form-group>
    </validation-provider>

    <div v-if="image">
      <b-row v-if="Array.isArray(image)">
        <b-col
          v-for="(imageSrc,index) in image"
          :key="index"
          md="2"
        >
          <b-img
            v-if="imageSrc"
            width="150px"
            :src="imageSrc"
          />
        </b-col>
      </b-row>
      <template v-else>
        <b-img
          width="150px"
          :src="image"
          thumbnail
        />
      </template>
    </div>

    <div v-else>
      <b-row v-if="Array.isArray(preview)">
        <b-col
          v-for="(imageSrc,index) in preview"
          :key="index"
          md="2"
        >
          <b-img
            v-if="imageSrc"
            width="150px"
            :src="`${$entitiesImagesURL}${imageSrc}`"
          />
        </b-col>
      </b-row>
      <template v-else-if="preview">
        <b-img
          width="150px"
          :src="`${$entitiesImagesURL}${preview}`"
          thumbnail
        />
      </template>
    </div>
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import convertImagesToBase64 from '@/common/compositions/common/convertImagesToBase64(New)'

export default {
  name: 'UploadImages',
  props: {
    rules: { type: String, default: '' },
    label: { type: String, default: '' },
    imageId: { type: String, default: '' },
    image: { type: [String, Array], default: '' },
    preview: { type: [String, Array], default: '' },
    isMultiple: { type: Boolean, default: false },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      handleImages,
    } = convertImagesToBase64()

    return {
      handleImages,
      getValidationState,
    }
  },
  methods: {
    onInput(images) {
      if (this.isMultiple) this.convertMultipleImages(images)
      else this.convertImage(images)
    },
    convertMultipleImages(images) {
      this.handleImages(images).then(imagesBase64 => {
        this.updateImage('preview', imagesBase64)
        this.updateImage('image', imagesBase64)
      })
    },
    convertImage(image) {
      this.handleImages([image]).then(imagesBase64 => {
        this.updateImage('preview', imagesBase64[0])
        this.updateImage('image', imagesBase64[0])
      })
    },
    updateImage(name, imagesBase64) {
      this.$emit(`update:${name}`, imagesBase64)
    },
  },
}
</script>
<style lang="scss">

</style>
