<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="inpoDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Mobile Number"
          rules="min:6|max:14"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="inpoDetails.phone_number"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="inpoDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="inpoDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-form-row>
      <!-- Classification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Classification"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="class_id"
              disabled
              :options="classifications"
              class="mb-3"
              value-field="id"
              text-field="name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="subClassification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
          >
            <v-select
              v-model="inpoDetails.subClassifications"
              label="name"
              :reduce="tag => tag.id"
              :options="subClassifications"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Donation Category -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Donation Category"
        >
          <b-form-group
            label="Donation Category"
            label-for="donation-category"
          >
            <v-select
              id="donation-category"
              v-model="inpoDetails.donation_categories"
              multiple
              label="category_name"
              :reduce="d => d.id"
              :options="donationCategories"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Language"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="inpoDetails.languages"
              multiple
              :reduce="lang => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
    <b-form-row>
      <!-- Location -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <input
              id="location"
              ref="locationRef"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Enter Address To Search"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="street"
          rules="required"
        >
          <b-form-group
            label="Street"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="inpoDetails.address[0].street"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="City"
          rules="required"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="inpoDetails.address[0].city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- State -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="State"
          rules="required"
        >
          <b-form-group
            label="Province / State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="inpoDetails.address[0].state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
    <b-form-row>
      <!-- Country -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="Country"
          rules="required"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="inpoDetails.address[0].country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="PostalCode"
          rules="required"
        >
          <b-form-group
            label="Zip / Postal Code"
            label-for="postalCode"
          >
            <b-form-input
              id="postalCode"
              v-model="inpoDetails.address[0].postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="latitude"
          rules="required"
        >
          <b-form-group
            label="Latitude"
            label-for="latitude"
          >
            <b-form-input
              id="latitude"
              v-model="inpoDetails.location.coordinates[0]"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Latitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="validationContext"
          name="longitude"
          rules="required"
        >
          <b-form-group
            label="Longitude"
            label-for="longitude"
          >
            <b-form-input
              id="longitude"
              v-model="inpoDetails.location.coordinates[1]"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Longitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
    <organization-upload-images
      :logo.sync="inpoDetails.logo"
      :cover.sync="inpoDetails.cover"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import organizationUploadImages from '@/common/components/common/Organizations/organizationUploadImages.vue'
import locationAutoComplete from '@/common/compositions/common/locationAutoComplete'

export default {
  components: {
    vSelect,
    organizationUploadImages,
  },
  props: {
    inpoDetails: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: { type: Array, default: () => [] },
  },
  data() {
    return {
      class_id: 1,
    }
  },
  mounted() {
    this.initAutocomplete(this.inpoDetails.address[0], this.inpoDetails.location)
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      initAutocomplete,
      locationRef,
    } = locationAutoComplete()

    return {
      getValidationState,
      initAutocomplete,
      locationRef,
    }
  },
}
</script>

<style lang="scss">

</style>
